const ROULETTE = {
  ORDER: [1, 14, 2, 13, 3, 12, 4, 0, 11, 5, 10, 6, 9, 7, 8],
  BOX_SIZE: 80,
  BOX_MARGIN: 7,
  GRID_REPEAT: 10,
  get GRID_WIDTH() { return this.ORDER.length * (this.BOX_SIZE + this.BOX_MARGIN) },
  ANIM_LENGTH: 6500,
  ANIM_CORRECTION_LENGTH: 500,
  ANIM_CORRECTION_WAIT: 800,
  ANIM_TRANSITION: 'cubic-bezier(0,0.5, 0, 1)',
  
  ROULETTE_TIME: 20,
  ROULETTE_COUNTDOWN_ACTIVATE: 0, // discontinued for now
  DISPLAY_RESULT_TIME: 2000,

  PREVIOUS_RESULT_COUNT: 10
}

const GENERIC = {
  PAGE_TRANSITION_TIME: 200
}

export {
  ROULETTE,
  GENERIC
}